import React from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";

import HomePage from "./pages/HomePage/HomePage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage/PrivacyPolicyPage";
import TermsOfUsePage from "./pages/TermsOfUsePage/TermsOfUsePage";
import SupportPage from "./pages/SupportPage/SupportPage";

const App = () => {
  return (
    <div>
      <Switch>
        <Route path="/privacypolicy" exact component={PrivacyPolicyPage} />
        <Route path="/termsofuse" exact component={TermsOfUsePage} />
        <Route path="/support" exact component={SupportPage} />
        <Route path="/" exact component={HomePage} />
        <Redirect to="/" />
      </Switch>
    </div>
  );
};

export default withRouter(App);
