import React from "react";
import { NavLink } from "react-router-dom";

import classes from "./NavBar.module.css";

const NavBar = (props) => {
  return (
    <div className={`${classes.HeaderContainer} ${props.className}`}>
      <NavLink to="/privacypolicy" className={classes.BarItem3}>
        <h3>Privacy</h3>
      </NavLink>
      <NavLink to="/termsofuse" className={classes.BarItem4}>
        <h3>Terms of Use</h3>
      </NavLink>
      <NavLink to="/support" className={classes.BarItem5}>
        <h3>Support</h3>
      </NavLink>
    </div>
  );
};

export default NavBar;
