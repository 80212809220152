import React, { useState } from "react";
import ExhibitUIcon from "../../assets/app-header.png";
import classes from "./HomePage.module.css";
import NavBar from "../../components/UI/NavBar/NavBar";
import { isMobile } from "react-device-detect";
import HeaderRightXButton from "../../components/UI-Mobile/HeaderRightXButton/HeaderRightXButton";
import HeaderRightNavbutton from "../../components/UI-Mobile/HeaderRightNavbutton/HeaderRightNavbutton";
import RightDrawerSlideIn from "../../components/UI-Mobile/RightDrawerSlideIn/RightDrawerSlideIn";
import { NavLink } from "react-router-dom";

const HomePage = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const renderHeader = () => {
    if (isMobile) {
      return (
        <div className={classes.HeaderContainer}>
          <div className={classes.Header}>
            <NavLink to="/">
              <img src={ExhibitUIcon} className={classes.ExhibitUIcon} />
            </NavLink>
            <div className={classes.NavContainer}>
              {isDrawerOpen ? (
                <HeaderRightXButton onClick={() => setIsDrawerOpen(false)} />
              ) : (
                <HeaderRightNavbutton onClick={() => setIsDrawerOpen(true)} />
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={classes.HeaderContainer}>
          <NavLink to="/">
            <img src={ExhibitUIcon} className={classes.ExhibitUIcon} />
          </NavLink>
          <div className={classes.HeaderBalance} />
        </div>
      );
    }
  };

  return (
    <div className={classes.MainContainer}>
      {isDrawerOpen && (
        <RightDrawerSlideIn nonDrawerClick={() => setIsDrawerOpen(false)} />
      )}
      {renderHeader()}
      <div className={classes.MainBodyContainer}>
        <div className={classes.TextContainer}>
          <div className={classes.MainTextContainer}>
            <h1 className={classes.MainText}>
              Welcome to ExhibitU, the showcasing platform
            </h1>
          </div>
          <div className={classes.SecondaryTextContainer}>
            <h2 className={classes.SecondaryText}>
              Quickly pull up your portfolio or hobbies and cheer on your
              friends as they post theirs
            </h2>
          </div>
        </div>
      </div>
      {!isMobile && (
        <div className={classes.FooterContainer}>
          <div className={classes.Footer}>
            <NavBar />
          </div>
          <div className={classes.FooterBalance} />
        </div>
      )}
    </div>
  );
};

export default HomePage;
