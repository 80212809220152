import React from "react";
import { NavLink } from "react-router-dom";
import { WithClass } from "../../../hoc/WithClass";

import classes from "./RightDrawerSlideIn.module.css";

const RightDrawerSlideIn = (props) => {
  return (
    <WithClass className={`${props.className}`}>
      <div
        onClick={props.nonDrawerClick}
        className={`${classes.NonDrawerContainer} ${props.nonDrawerContainerStyle}`}
      />
      <WithClass
        className={`${classes.DrawerContainer} ${props.drawerContainerStyle}`}
      >
        <NavLink to="/privacypolicy" className={classes.BarItem1}>
          <h3 className={classes.TextItem1}>
            Privacy Policy
            <div className={classes.underlineEffect1} />
          </h3>
        </NavLink>
        <NavLink to="/termsofuse" className={classes.BarItem2}>
          <h3 className={classes.TextItem2}>
            Terms of Use
            <div className={classes.underlineEffect2} />
          </h3>
        </NavLink>
        <NavLink to="/support" className={classes.BarItem3}>
          <h3 className={classes.TextItem3}>
            Support
            <div className={classes.underlineEffect3} />
          </h3>
        </NavLink>
      </WithClass>
    </WithClass>
  );
};

export default RightDrawerSlideIn;
